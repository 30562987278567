/* src/styles/animations.css */

/* Animação de desvanecimento */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Animação de zoom para fora */
@keyframes scaleDown {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.9);
  }
}

/* Animação de rotação */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animação de deslizamento para a direita */
@keyframes slideInRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animação de deslizamento para a esquerda */
@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

/* Animação de pulso */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Animação de balançar */
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
}

@keyframes enterFromBottom {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-enter-from-down {
  animation: enterFromBottom 0.3s ease-out;
}

/* Classes CSS para aplicar as animações */
.fade-out {
  animation: fadeOut 0.5s ease-in-out forwards;
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}

.scale-down {
  animation: scaleDown 0.3s ease-in-out forwards;
}

/* Animação de zoom no hover */

.scale-zoom-1:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
.scale-zoom-2:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}
.scale-zoom-3:hover {
  transform: scale(1.3);
  transition: transform 0.3s ease-in-out;
}

.rotate {
  animation: rotate 1s linear infinite;
}

.slide-in-right {
  animation: slideInRight 0.5s ease-in-out forwards;
}

.slide-out-left {
  animation: slideOutLeft 0.5s ease-in-out forwards;
}

.pulse {
  animation: pulse 1.6s ease-in-out infinite;
}

.shake {
  animation: shake 0.5s ease-in-out;
}
